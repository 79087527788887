<template>
  <div class="grilla-resumen col-9">
    <div class="votos  ">
      <p>
       <div id="encabezado">
        {{ texto }}
       </div>  <br />
       <div class="numero">
        {{ numero }}
       </div>
      </p>
    </div>
    <div class="porcentaje ">
      <p>
        {{ porcentaje }}%
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ResumenVotacion',
  props: {
    numero: String,
    porcentaje: String,
    texto: String,
  },
};
</script>

<style lang="scss" scoped>
.grilla-resumen {
  display: grid;
  grid-template-columns: 1fr 70px;
  align-items: flex-end;
  border-bottom: 1px solid #ddd;  
  
  margin-bottom: 15px;
  #encabezado{
    font-weight: 700;
    font-size: 18px;
    padding-left: px;
    text-align: left;
    // margin-bottom: 10px;
  }
  p {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 10px;
    
  }
  .numero{
    text-align: center;
    font-weight: bold;
    // padding-left: 20px;
  }
  .porcentaje {
    // text-align: right;
    margin-right: 15px;
  }
}
</style>

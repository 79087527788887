<script>
//deberá enviar <small>Participación ciudadana <br /></small> en la leyenda cuando de eso  se trate
export default {
  name: 'ParticipacionCiudadana',
  props: {
    numero: String,
    porcentaje: Number,
    leyenda: String,
    esCasilla: Boolean,
  },
  render () {
    return (
      <div>
        <h3>
          Estadística de Casilla
        </h3>

        <p class="font-weight-bold px-4 mt-4">
          <u>
            Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
          </u>
        </p>

        <div class="row mb-3 px-4">
          <div class="col-9">
            <span class="porcentaje-participacion-ciudadana">
              <h4>
                <small>
                  { this.leyenda } <br />
                </small>
                { this.porcentaje }%
              </h4>
            </span>
          </div>
          <div class="col-3">
            <p class="font-weight-bold text-right mb-0">
              Total de la Lista Nominal
            </p>
            <h2 class="font-weight-bold text-right">
              { 
                this.numero
              }
            </h2>
          </div>
          <div class="col-12">
            <b-progress max="100" value={this.porcentaje} />
          </div>
        </div>

        {
          this.esCasilla && (
            <div class="row px-4">
              <div class="col-12">
                <p class="small">
                  <small>
                    <b>
                      El cálculo de Participación ciudadana puede rebasar el 100% si vota toda la ciudadanía de la Lista Nominal de una casilla; así como las Representaciones de Partidos Políticos y Candidaturas Independientes.
                    </b>
                  </small>
                </p>
              </div>
            </div>
          )
        }
      </div>
    )
  }
}
</script>

<style lang="scss" scoped>
.porcentaje-participacion-ciudadana {
  /* position: absolute; */
  top: 9px;
  left: 35px;
  /* color: #FFF; */
}

.progress {
  background-color: #F4F4F4;
  /* height: 70px !important; */
  height: 3.3rem;
}
</style>

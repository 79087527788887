import {
  computed, ref, watch, watchEffect,
} from '@vue/composition-api';
import useCalcularUrlActaEscaneada from '@/composables/useCalcularUrlActaEscaneada';
import useFiltrarDatosSeccion from '@/composables/useFiltrarDatosSeccion';

function useDetalleCasilla(root) {
  const { eleccion } = root.$route.params;

  // Computadas
  const state = computed(() => root.$store.state);
  const tituloEleccion = computed(() => {
    if (root.$route.params.eleccion === 'G') {
      return 'Gubernatura';
    } if (root.$route.params.eleccion === 'D') {
      return 'Diputaciones';
    } if (root.$route.params.eleccion === 'A') {
      return 'Ayuntamientos';
    }
    return '';
  });
  const distrito = computed(() => root.$route.params.distrito);
  const distritoNombre = computed(() => state.value.elementosGenerales.datos_distritos
    .find((d) => parseInt(d.id, 10) === parseInt(distrito.value, 10))
    .descripcion);
  const seccion = ref(root.$route.params.seccion);
  // const casilla = ref(root.$route.params.casilla);
  const casilla = ref(root.$route.params.casilla || "");

  const actualizarCasilla = () => {
    casilla.value = root.$route.params.casilla || "";
    console.log("Valor actual de casilla:", casilla.value);
  };

  // Llamamos a actualizarCasilla() una vez al inicio
  actualizarCasilla();

  // Luego, escuchamos cambios en la ruta y actualizamos la casilla
  root.$router.afterEach(() => {
    actualizarCasilla();
  });
  const ayuntamiento = computed(() => state.value
    .elementosGenerales.datos_municipios[distrito.value - 1]);
  const votosXCasilla = computed(() => {
    
    const catalogo = {
      G: {
        tipo: 'gVotosXCasilla',
        area: 'datos_distritos',
      },
      A: {
        tipo: 'aVotosXCasilla',
        area: 'datos_municipios',
      },
      D: {
        tipo: 'dVotosXCasilla',
        area: 'datos_distritos',
      },
    };
    
    const catalogoSeleccionado = catalogo[eleccion];
    const estado = state.value[catalogoSeleccionado.tipo];
    
    return useFiltrarDatosSeccion(distrito.value, seccion.value, casilla.value)
      .filtrarDatosCasilla(estado, catalogoSeleccionado.area);
  });

  const votosXCasillaTarjetas = computed(() => {
    const catalogo = {
      G: {
        tipo: 'gVotosXCasillaTarjeta',
        area: 'datos_distritos',
      },
      A: {
        tipo: 'aVotosXCasillaNivelDistrito',
        area: 'datos_municipios',
      },
      D: {
        tipo: 'dVotosXCasillaTarjeta',
        area: 'datos_distritos',
      },
    };

    const catalogoSeleccionado = catalogo[eleccion];
    const estado = state.value[catalogoSeleccionado.tipo];

    return useFiltrarDatosSeccion(distrito.value, seccion.value, casilla.value)
      .filtrarDatosCasilla(estado, catalogoSeleccionado.area);
  });

  const resumen = computed(() => {
    const catalogo = {
      G: {
        tipo: 'gResumenNivelCasilla',
        area: 'datos_distritos',
      },
      A: {
        tipo: 'aResumenNivelCasilla',
        area: 'datos_municipios',
      },
      D: {
        tipo: 'dResumenNivelCasilla',
        area: 'datos_distritos',
      },
    };

    const estado = state.value[catalogo[eleccion].tipo];

    return useFiltrarDatosSeccion(distrito.value, seccion.value)
      .filtrarDatosSeccion(estado, catalogo[eleccion].area)
      .datos_casillas
      // eslint-disable-next-line max-len
      .filter((casillaInterna) => parseInt(casillaInterna.seccion_id, 10) === parseInt(seccion.value, 10)
        && casillaInterna.casilla_tipo === casilla.value)[0];
  });

  const estadisticas = computed(() => {
    const catalogo = {
      G: {
        tipo: 'gEstadisticaNivelCasilla',
        area: 'datos_distritos',
      },
      A: {
        tipo: 'aEstadisticaNivelCasilla',
        area: 'datos_municipios',
      },
      D: {
        tipo: 'dEstadisticaNivelCasilla',
        area: 'datos_distritos',
      },
    };

    const estado = state.value[catalogo[eleccion].tipo];
    if(tituloEleccion.value === 'Ayuntamientos'){
      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area)
        .datos_casillas
        // eslint-disable-next-line max-len
        .filter((casillaInterna) => casillaInterna.casilla_tipo === casilla.value)[0];
    }else{
      return useFiltrarDatosSeccion(distrito.value, seccion.value)
        .filtrarDatosSeccion(estado, catalogo[eleccion].area)
        .datos_casillas
        // eslint-disable-next-line max-len
        .filter((casillaInterna) => parseInt(casillaInterna.seccion_id, 10) === parseInt(seccion.value, 10)
          && casillaInterna.casilla_tipo === casilla.value)[0];
    }
  });

  const listaSecciones = computed(() => {
    if (eleccion !== 'A') {
      return state.value.elementosGenerales.datos_secciones
        .distritos[distrito.value - 1]
        .datos_secciones;
    }

    return state.value.elementosGenerales
      .datos_secciones_por_municipio[distrito.value - 1]
      .datos_secciones;
  });

  const listaCasillas = computed(() => state.value.elementosGenerales
    .datos_casillas
    .filter((item) => parseInt(item.seccion_id, 10) === parseInt(seccion.value, 10))[0]);

  // eslint-disable-next-line max-len
  const nivelProcedenciaCasilla = computed(() => parseInt(votosXCasilla.value.datos_votos[1].candidatura_logo, 10));

  // Funciones
  const cambiarSeccion = () => {
    root.$router.push(`/${eleccion}/Secciones/${distrito.value}/${seccion.value}`);
  };

  const irADetalleDeCasilla = () => {
    root.$router.push(`/${eleccion}/Secciones/${distrito.value}/${seccion.value}/${casilla.value}`);
  };

  return {
    ...useCalcularUrlActaEscaneada(),
    votosXCasilla,
    distrito,
    seccion,
    tituloEleccion,
    ayuntamiento,
    casilla,
    resumen,
    votosXCasillaTarjetas,
    estadisticas,
    distritoNombre,
    cambiarSeccion,
    listaSecciones,
    irADetalleDeCasilla,
    listaCasillas,
    eleccion,
    nivelProcedenciaCasilla,
  };
}

export default useDetalleCasilla;

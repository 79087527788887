function useFiltrarDatosSeccion(distrito, seccion, casilla = null) {
  // eslint-disable-next-line arrow-body-style
  // console.log("Distrito: " +distrito);
  // console.log("Seccion: " +seccion);
  const filtrarDatosSeccion = (estado, area) => {
    // console.log(estado);
    // console.log(area);
    
    
    return estado[area][distrito - 1]
      .datos_secciones.filter((seccionInterna) => {
        const seccionInternaParseada = parseInt(seccionInterna.seccion_id, 10);
        const seccionParseada = parseInt(seccion, 10);
        return seccionInternaParseada === seccionParseada;
      })[0];
  };

  // eslint-disable-next-line arrow-body-style
  const filtrarDatosCasilla = (estado, area) => {
    console.log(estado);
    console.log(area);
    // debugger
    console.log("Casilla: " ,casilla);
    console.log("Text",estado[area][distrito - 1]
      .datos_secciones.filter((seccionInterna) => {
        
        const seccionInternaParseada = parseInt(seccionInterna.seccion_id, 10);
        const seccionParseada = parseInt(seccion, 10);
        return seccionInternaParseada === seccionParseada;
      })[0]);
    return estado[area][distrito - 1]
      .datos_secciones.filter((seccionInterna) => {
        
        const seccionInternaParseada = parseInt(seccionInterna.seccion_id, 10);
        const seccionParseada = parseInt(seccion, 10);
        return seccionInternaParseada === seccionParseada;
      })[0]
      .datos_casillas
      .filter((casillaInterna) => casillaInterna.casilla_tipo === casilla)[0];
  };

  return {
    filtrarDatosSeccion,
    filtrarDatosCasilla,
  };
}

export default useFiltrarDatosSeccion;

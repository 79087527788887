<template>
  <div class="contenedor-padre">
    <button
      class="btn-fijo btn-izquierda"
      @click="scrollIzquierda"
      :disabled="horizontalScrollValue === 0"
    >
      <font-awesome-icon icon="chevron-left" class="fa-3x" />
    </button>

    <div class="rowFlex" id="row-flex">
      <div
        v-for="(candidato, index) in votos.datos_candidaturas || votos.datos_votos.slice(2,7)"
        :key="index"
        style="padding:10px;"
        :class="candidato.candidatura_id === votos.candidato_id_con_mayor_numero_votos || candidato.candidato_id === votos.candidato_id_con_mayor_numero_votos
                ? 'resumen-voto-candidatura2 '
                : ''"
      >
        <div class="resumen-voto-candidatura ">
        
         <div class="contorno">

          <div class="grilla-datos ">
            <div class="grilla-datos-candidatura">
              
              <div  class="foto" style="text-align: center">
                <img
                  :src="candidato.candidatura_logo"
                  :alt="candidato.candidatura_nombre"
                  style="max-height: 40px"
                />
              </div>
            <div class="nombre text-center mb-4">
            </div>
            </div>
          </div>
       
          <div class="grilla-resumen">
            <div class="total text-left">
              <p class="mb-0 font-weight-bold">
                Votos <br />
                <span v-if="candidato.candidatura_total_votos_numero">
                  {{ candidato.candidatura_total_votos_numero }}
                </span>
                <span v-else>
                  {{ candidato.candidatura_votos_cantidad }}
                </span>
              </p>
            </div>
            <div class="porcentaje text-righd">
              <p class="mb-0 font-weight-bold">
                <span v-if="candidato.candidatura_total_votos_porcentaje">
                  {{ candidato.candidatura_total_votos_porcentaje }}%
                </span>
                <span v-else>
                  {{ candidato.candidatura_votos_porcentaje }}%
                </span>
              </p>
            </div>
          </div>
          
         </div>

        </div>
      </div>
    </div>

    <button
      class="btn-fijo btn-derecha"
      @click="scrollDerecha"
      :disabled="horizontalScrollValue > maximoLogitudScroll"
    >
      <font-awesome-icon icon="chevron-right" class="fa-3x" />
    </button>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';

export default defineComponent({
  name: 'SliderVotos',
  props: {
    votos: Object,
  },
  setup() {
    const constanteScroll = 150;
    const maximoLogitudScroll = ref(0);
    const horizontalScrollValue = ref(0);

    const scrollDerecha = () => {
      const row = document.getElementById('row-flex');
      if (horizontalScrollValue.value < (row.scrollWidth)) {
        horizontalScrollValue.value += constanteScroll;
      }
      row.scrollTo(horizontalScrollValue.value, 0);
    };

    const scrollIzquierda = () => {
      const row = document.getElementById('row-flex');
      if (horizontalScrollValue.value > 0) {
        horizontalScrollValue.value -= constanteScroll;
      }
      row.scrollTo(horizontalScrollValue.value, 0);
    };

    onMounted(() => {
      const row = document.getElementById('row-flex');
      maximoLogitudScroll.value = row.scrollWidth - row.offsetWidth;
    });

    return {
      scrollDerecha,
      scrollIzquierda,
      horizontalScrollValue,
      maximoLogitudScroll,
    };
  },
});
</script>

<style lang="scss" scoped>


.rowFlex {
  display: flex;
  flex-flow: nowrap;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 1rem;
  scroll-behavior: smooth; 
}
.resumen-voto-candidatura {

    
      // min-height: 100%;
      display: grid;
      grid-template-rows: 1fr;
      max-width: 150px;    
      
    .contorno{
      border: 1px solid #000000;
    }   
    .grilla-datos-candidatura {
      margin: 10px;
      .foto > svg {
        max-width: 50px;
        margin: 0 auto;
        display: block;
        margin-bottom: 10px;
      }
    }
    .grilla-resumen {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-end;
      gap: 15px;
      margin-top: 10px;
      background-color: #f4f4f4;
      padding: 5px;
    }
}
.resumen-voto-candidatura2 {

  min-height: 100%;
  display: grid;
  // grid-template-rows: 1fr;
  border: 3px solid #D2127E;
  // max-width: 150px;

  // .grilla-datos-candidatura {
  //   margin: 10px;
  //   .foto > svg {
  //     max-width: 50px;
  //     margin: 0 auto;
  //     display: block;
  //     margin-bottom: 10px;
  //   }
  // }
  // .grilla-resumen {
  //   display: grid;
  //   grid-template-columns: 1fr 1fr;
  //   align-items: flex-end;
  //   gap: 15px;
  //   margin-top: 10px;
  //   background-color: #f4f4f4;
  //   padding: 5px;
  // }
 
   
    
  
}


.rowFlex::-webkit-scrollbar
{
  width: 11px;
  &:hover {
    cursor: pointer;
  }
}

.rowFlex::-webkit-scrollbar-thumb
{
  background-color: #90A4AE;
  border-radius: 6px;
  border: 3px solid #CFD8DC;

  &:hover {
    cursor: pointer;
  }
}

.contenedor-padre {
  display: grid;
  grid-template-columns: 30px 1fr 30px;
  align-items: center;
  gap: 15px;

  .btn-fijo {
    background: transparent;
    border: none;
    color: #707070;
    z-index: 1;
    transition: all ease .2s;

    &:disabled {
      cursor: not-allowed;
      opacity: 50%;
    }
  }
}
</style>

<template>
  <div>
    <div class="votosEnActasContabilizadas">
      <p class="titulo">
        Estadística de la Casilla Sección {{ seccion }} {{ casilla }}
      </p>

      <h5 class="font-weight-bold mb-3">
        Participación ciudadana con base en la Lista Nominal de las Actas PREP Contabilizadas
      </h5>
    </div>

    <div
      class="votosTotalesAvance mb-4"
    >
      <div class="grilla mb-2">
        <div class="leyenda">
          <p class="font-weight-bold mb-0">
            {{ seccion }} Casilla {{ casilla }}
          </p>
        </div>
        <div class="total">
          <h4 class="mb-0">
            {{ estadisticas.participacion_ciudadana_porcentaje }}%
          </h4>
        </div>
      </div>
      <b-progress max="100" :value="estadisticas.participacion_ciudadana_porcentaje" />
    </div>

    <div class="listaNominal mb-5">
      <h5 class="font-weight-bold mb-3">
        Lista Nominal
      </h5>

      <h1 class="text-center font-weight-bold mb-1">
        {{  estadisticas.total_lista_nominal_numero }}
      </h1>
      <p class="text-center small mb-4">
        Lista Nominal de Actas Contabilizadas
      </p>

      <p class="text-center small">
        El cálculo de Participación Ciudadana puede rebasar el 100% si votan todos los
        ciudadanos de la Lista Nominal de una casilla; así como los Representantes de
        Partido Político y Candidaturas Independientes.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EstadisticaCasilla',
  props: {
    seccion: String,
    casilla: String,
    estadisticas: Object,
  },
};
</script>

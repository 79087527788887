<template>
  <div class="vista-escritorio">
    <Cabecera />
    <Cuerpo>
      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <h2>
              {{ tituloEleccion }} - <span><strong>Sección - Casilla </strong></span>
            </h2>

            <h4>
              <strong>
                <router-link :to="`/${this.$route.params.eleccion}/ENT/VPC`" class="estado-volver">
                  Baja California Sur
                </router-link>
              </strong>
              /
              <router-link
                :to="`/${this.$route.params.eleccion}/DET/VPC/${distrito}`"
                class="estado-volver"
              >
                <span
                  v-if="this.$route.params.eleccion !== 'A'"
                >
                  Distrito {{ distrito }}. {{ distritoNombre }}
                </span>
                <span v-else>
                  Municipio {{this.distrito}}. {{ ayuntamiento.municipio_descripcion }}
                </span>
              </router-link>
              /
              <router-link
                :to="`/${this.$route.params.eleccion}/Secciones/${distrito}/${seccion}`"
                class="estado-volver"
              >
                Sección {{ seccion }}
              </router-link> / {{ casilla }}
            </h4>

            <p>
              El total de votos calculado y porcentaje que se muestran, se refieren a los votos
              asentados en las actas PREP hasta el momento. <br />
            
            </p>

            <!-- <h3>Votos en Actas Contabilizadas</h3> -->
            <h3>Información del Acta</h3>

            <div class="grilla-estado-acta mb-4">
              <div>
                <p class="small font-weight-bold">Acta digitalizada:</p>
                <a
                  :href="calcularUrlActaDigitalizada(votosXCasilla.datos_votos[1])"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <button class="recarga btn-ver">
                   
                    <nivel-procedencia
                      :procedencia="nivelProcedenciaCasilla"
                    />
                    <div class="texto">
                      <span v-if='votosXCasilla.datos_votos[1].candidatura_logo !== 0'>
                        Ver
                      </span>
                      <span v-else>
                        En proceso
                      </span>
                    </div>
                  </button>
                </a>
              </div>
              <procedencia-acta />
            </div>
          </div>
        </div>
        <SliderVotos :votos="votosXCasillaTarjetas" />

        <div class="votos-cabecera">
          <div class="titulos">
            <div class="row px-3">
              <div class="col-8 mb-3 resumen-votacion">
                <h4 class="mb-2">Resumen de la votación</h4>
                <div class="grilla-suma">
                  <div class="acumulados text-center">
                    <p class="font-weight-bold">
                      Votos acumulados
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext">
                          Es la sumatoria de los votos obtenidos <br />
                          por Partido Político, Candidatura Independiente
                          y combinaciones de Coalición.
                        </span>
                      </span>
                      <br />
                      {{ resumen.votos_acumulados_numero }} <br />
                      <span class="porcentaje">
                        {{ resumen.votos_acumulados_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="no-registradas text-center">
                    <p class="font-weight-bold">
                      Candidaturas no registradas <br />
                      {{ resumen.candidaturas_no_registradas_numero }}
                      <br />
                      <span class="porcentaje">
                        {{ resumen.candidaturas_no_registradas_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="plus" class="fa-2x" />
                  </div>
                  <div class="nulos text-center">
                    <p class="font-weight-bold">
                     Votos Nulos 
                     <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext">
                          El total y el porcentaje de votos para candidaturas no registradas
                          y nulos, considera la votación en Casillas
                          y Anticipados.
                        </span>
                      </span>
                     <br />
                      {{ resumen.nulos_numero }} <br />
                      <span class="porcentaje">
                        {{ resumen.nulos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                  <div class="icono-suma">
                    <font-awesome-icon icon="equals" class="fa-2x" />
                  </div>
                  <div class="total text-right">
                    <p class="font-weight-bold">
                      Total de votos 
                      <span id="tooltip">
                        <font-awesome-icon
                          icon="info-circle"
                          class="fa-sm fa-info"
                        />
                        <span class="tooltiptext">
                          Se refiere a la suma total de votos que realiza el sistema informático
                          con en los datos que muestran en cada Acta; podrás consultar la cifra 
                          del total de Votos mostrado en cada Acta en la base de datos del PREP
                          (numeral 26, fr.XIV del Anexo 13 del Reglamento de Elecciones).
                        </span>
                      </span>
                      
                      <br />
                      <big>{{ resumen.total_votos_numero }}</big> <br />
                      <span class="porcentaje">
                        {{ resumen.total_votos_porcentaje }}%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
              <div class="col-3 mb-3 resumen-votacion">
                <h4 class="mb-2">Observaciones en Acta</h4>
                <div>{{resumen.observarciones_acta}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ParticipacionCiudadana
              :numero="estadisticas.total_lista_nominal_numero"
              :porcentaje="parseFloat(estadisticas.participacion_ciudadana_porcentaje)"
              :leyenda="estadisticas.casilla_nombre"
              :esCasilla="true"
            />
          </div>
        </div>
      </div>

      <div class="votos">
        <div class="votos-cabecera">
          <div class="titulos">
            <ObservacionesActas />
          </div>
        </div>
      </div>
      <div class="text-center">
        <p>
          Los resultados presentados tienen un carácter informativo y no son
          definitivos, por tanto, no tienen efectos jurídicos.
          Federal.
        </p>
      </div>
    </Cuerpo>
    <Compartir />
    <PieDePagina />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useDetalleCasilla from '@/composables/UseDetalleCasilla';

import Cabecera from '@/components/Escritorio/componentes/Cabecera.vue';
import Cuerpo from '@/components/Escritorio/componentes/Cuerpo.vue';
import Compartir from '@/components/Compartir.vue';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas.vue';
import PieDePagina from '@/components/Escritorio/componentes/PieDePagina.vue';
import ParticipacionCiudadana from '@/components/Escritorio/componentes/ParticipacionCiudadana.vue';
import SliderVotos from '@/components/Escritorio/componentes/SliderVotos.vue';
import ProcedenciaActa from '@/components/ProcedenciaActa.vue';
import NivelProcedencia from '@/components/NivelProcedencia.vue';

export default defineComponent({
  name: 'SeccionCasillaEscritorio',
  components: {
    Cabecera,
    Cuerpo,
    Compartir,
    PieDePagina,
    ObservacionesActas,
    ParticipacionCiudadana,
    ProcedenciaActa,
    SliderVotos,
    NivelProcedencia,
  },
  setup(_, { root }) {
    if (typeof root === 'undefined') {
      return {};
    }
    return useDetalleCasilla(root);
  },
  watch: {
    $route(to, from) {
      if (to.name === from.name) {
        this.seccion = to.params.seccion;
      }
    },
  },
  computed: {
    resumen() {
      if(typeof this.$route.params !== 'undefined'){
        if (this.$route.params.eleccion === 'G') {
          return this.$store.state.gResumenNivelCasilla
            .datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => parseInt(casilla.seccion_id) == parseInt(this.seccion)
                            && casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'D') {
          return this.$store.state.dResumenNivelCasilla
            .datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => parseInt(casilla.seccion_id) == parseInt(this.seccion)
                            && casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'A') {
          return this.$store.state.aResumenNivelCasilla
            .datos_municipios[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        }
      }
      return '';
    },
    votosXCasillaTarjetas() {
      if(typeof this.$route.params !== 'undefined'){
        if (this.$route.params.eleccion === 'G') {
          return this.$store.state.gVotosXCasillaTarjeta.datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'D') {
          return this.$store.state.dVotosXCasillaTarjeta.datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'A') {
          return this.$store.state.aVotosXCasillaNivelDistrito.datos_municipios[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        }
      }
      return '';
    },
  },
});
</script>

<style lang="scss" scoped>
.resumen-voto-candidatura {
  min-height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  padding: 3px;
  border: 1px solid #582D73;
  .grilla-datos-candidatura {
    margin: 10px;
    .foto > svg {
      max-width: 50px;
      margin: 0 auto;
      display: block;
      margin-bottom: 10px;
    }
    .nombre{
      width: 168px;
    }
  }
  .grilla-resumen {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 15px;
    margin-top:10px;
  }
}

.ganador {
  border-color: #D2127E;
  border-width: 3px;
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;

  .total {
    p > span {
      color: #D2127E;
    }
  }
}

.boton-seleccionar {
  width: 100%;
  display: block;
  text-align: center;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  background-color: #582D73;
  border-color: transparent;

  label {
    border: none;
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }
}

.seleccionado {
  background-color: lighten(#582D73, 10%);
}

.resumen-votacion {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 15px;

  .grilla-suma {
    display: grid;
    grid-template-columns: 1fr 20px 1fr 20px 1fr 20px 1fr;
    /* max-width: 900px; */
    align-items: center;
    gap: 30px;

    .acumulados, .nulos, .no-registradas, .total {
      p {
        color: #582D73;
      }
    }

    .total {
      big {
        font-size: 2rem;
      }
      big, .porcentaje  {
        color: #582D73;
      }
    }
  }
}

.table-responsive {
  thead {
    tr {
      background-color: #582D73;
      color: #fff;

      th {
        vertical-align: middle;
        img {
          max-height: 35px;
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        img {
          max-width: 50px;
        }
      }
      .votos {
        text-align: center;
        p {
          font-size: 2rem !important;
          font-weight: bold !important;
        }
      }
    }
  }
}

.boton-verdetalle {
  background-color: #582D73;
  border-color: transparent;
}
.rowActas {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  .elemento {
    /* max-width: 140px; */
    display: inline-block;
    margin-right: 30px;
    border: 1px solid black;
    padding: 0;
    img {
      max-width: 40px;
      padding-bottom: 20px;
      padding-top: 20px;

    }

    .grilla-partidos {
      display: grid;
      background-color: #F4F4F4;
      grid-template-columns: 1fr 1fr;
      gap: 30px;
      padding: 10px;
      align-items: flex-end;
    }
  }
}

.porcentaje-participacion-ciudadana {
  position: absolute;
  top: 9px;
  left: 35px;
  color: #fff;
}

.progress {
  background-color: #f4f4f4;
  height: 70px !important;
}

.grilla-estado-acta {
  display: inline-flex;
  grid-template-columns: 150px 1fr;
  gap: 30px;

  .acta-digitalizada {
    .btn {
      background-color: #d2127e !important;
      border-color: transparent;
    }
  }
}

.recarga {
      float: right;
      border: none;
      border-radius: 10px;
      padding: 7px 10px;
      width: 250px;
      background-color: #C791A8;
      color: #fff;
    }

.rowFlex {
  display: flex;
  flex-flow: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.btn-ver {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  gap: 5px;

  .texto {
    text-align: left;
  }
  .icono-tabla {
    text-align: right;
    margin-bottom: 5px;
    font-size: 26px;
  }
}
</style>

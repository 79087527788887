<template>
  <Layout :seccion="true">
    <div class="row votosPorCandidaturaMovil" v-if="cargaTerminada">
      <div class="col-12 col-md-10 mx-auto">
        <div class="grilla-selects">
          <div class="secciones">
            <select v-model="seccion" @change="cambiarSeccion">
              <option v-for="(seccion, index) in listaSecciones"
                    :key="index"
                    :value="seccion.seccion_id">
                Sección {{seccion.seccion_id}}
              </option>
            </select>
          </div>
          <div class="casillas">
            <select v-model="casilla" @change="irADetalleDeCasilla">
              <option v-for="(casilla, index) in listaCasillas.datos_casillas"
              :key="index"
              :value="casilla.tipo_casilla">{{casilla.tipo_casilla}}</option>
            </select>
          </div>
        </div>

        <h4>
          {{ tituloEleccion }} -
          <b class="primario">Sección Casilla </b>
        </h4>

        <p>
          <router-link :to="`/${eleccion}/ENT/VPC`" class="estado-volver">
            Baja California Sur
          </router-link> /
          <router-link :to="`/${eleccion}/DET/VPC/${distrito}`" class="estado-volver">
            <span
              v-if="tituloEleccion !== 'Ayuntamiento'"
            >
              Distrito {{ distrito }}. {{ distritoNombre }}
            </span>
            <span v-else>
              Municipio {{ distrito }}. {{ ayuntamiento.municipio_descripcion }}
            </span>
          </router-link> /
          <router-link :to="`/${eleccion}/Secciones/${distrito}/${seccion}`" class="estado-volver">
            Sección {{ seccion }}
          </router-link> /
          <b>{{ casilla }}</b>
        </p>

        <p>
          Cantidad de votos en cada una de las casillas de esta Sección,
          conforme a la información de las Actas de Escrutinio y Cómputo.
        </p>

    

        <div class="votosEnActasContabilizadas ">
          <p class="titulo">
            Votos en Actas Contabilizadas
          </p>

          <p class="mb-2">
            Ver Acta Digitalizada en:
          </p>

          <div class="ml-5 mt-4 boton d-flex mb-4">
              <a
              :href="calcularUrlActaDigitalizada(votosXCasilla.datos_votos[1])"
              target="_blank"
              rel="noopener noreferrer"
            >
              <!-- <button class="btn btn-primary btn-ver-acta mb-5" v-if='votosXCasilla.datos_votos[1].candidatura_logo !== 0'>
                <font-awesome-icon
                  :icon="['far', 'file']"
                  class="mx-1"
                ></font-awesome-icon>
                <font-awesome-icon icon="print" class="mx-1" />
                  Ver Acta
                </button>

                <button :disabled="true" class="btn btn-primary btn-ver-acta-proceso mb-5" v-else>
                  <font-awesome-icon
                    :icon="['far', 'file']"
                    class="mx-1"
                  ></font-awesome-icon>
                  <font-awesome-icon icon="print" class="mx-1" />
                    En proceso
                </button> -->
               
                <button class="recarga btn-ver" v-if='votosXCasilla.datos_votos[1].candidatura_logo !== 0'>                  
                 
                   <div class="texto">
                    <font-awesome-icon
                      :icon="['far', 'file']"
                      class="mx-1"
                    ></font-awesome-icon>
                    <font-awesome-icon icon="print" class="mx-1" />
                
                     <span v-if='votosXCasilla.datos_votos[1].candidatura_logo !== 0'>
                       Ver
                     </span>
                     <!-- <span class="enproceso" v-else >
                       En proceso
                     </span> -->
                   </div>
                 </button>

                 <button class="recarga2 " v-else>                   
                 
                   <div class="texto">
                    <font-awesome-icon
                      :icon="['far', 'file']"
                      class="mx-1"
                    ></font-awesome-icon>
                    <font-awesome-icon icon="print" class="mx-1" />                
                    
                     <span  >
                       En proceso
                     </span>
                   </div>
                 </button>
              </a>
          </div>
        </div>

        <div
          class="row"
        >
          <div
            class="col-12 col-sm-6 mb-4"
            v-for="(candidato, index) in votosXCasillaTarjetasM.datos_votos"
            :key="index"
          >
            <!--<div class="resumen-voto-candidatura" :class="{ 'ganador': candidato.ganador }">-->

            <div v-if="candidato.candidatura_id !='99999999'   && candidato.candidatura_id !=9999 && candidato.candidatura_id !=99999 && candidato.candidatura_id !=999999999"  class="resumen-voto-candidatura d-flex justify-content-between ">
              <div class="ml-3 ">
              <!-- <div class="grilla-datos-candidatura"> -->
                <div class="foto mt-2" >
                  <!--<Avatar :colores="colores" />-->
                  <img v-if="candidato.candidatura_logo !== 'sin logo'"
                    :src="candidato.candidatura_logo"
                    :alt="candidato.candidatura_nombre"
                    style="max-height:40px;"
                  >
                  
                  <span v-else>
                    {{ candidato.candidatura_nombre }}
                  </span>

                </div>
             
              </div>
              <div class="mr-4 mb-2">
              <!-- <div class="grilla-votos mb-2"> -->
                <div class="total text-right mt-1">
                  <p class="mb-0 font-weight-bold">
                    Total de votos <br />
                    <span>
                      {{ candidato.candidatura_votos_cantidad }}
                    </span>
                  </p>
                </div>
              
              </div>
            </div>

          </div>
        </div>

        <div>
          <p>
            Por presentación, los decimales de los porcentajes muestran sólo cuatro dígitos.No obstantes,
            al considerar todos los decimales, suman 100%.
          </p>

        </div>

        <div class="resumenDeLaVotacion mb-4">
          <p class="titulo">
            Resumen de la votación
          </p>

          <ResumenVotacion texto="Votos Acumulados"
            :numero="resumen.votos_acumulados_numero"
            :porcentaje="resumen.votos_acumulados_porcentaje"
          />
          <ResumenVotacion texto="Candidaturas no registradas"
            :numero="resumen.candidaturas_no_registradas_numero"
            :porcentaje="resumen.candidaturas_no_registradas_porcentaje"
          />
          <ResumenVotacion texto="Nulos"
            :numero="resumen.nulos_numero"
            :porcentaje="resumen.nulos_porcentaje"
          />
        </div>

        <div class="totalVotos mb-4">
          <h5 class="text-center"><b>Total de votos</b></h5>

          <h1 class="total mb-0">
            {{ resumen.total_votos_numero }}
          </h1>
          <p class="porcentaje mb-3">
            {{resumen.total_votos_porcentaje}}%
          </p>
        </div>
        <div class="col-12 mb-3 resumen-votacion">
            <h5 class="mb-2 pt-1"> <b>Observaciones en Acta</b> </h5>
            <div class="mt-2">
                {{resumen.observarciones_acta}}
            </div>
              
        </div>
        <EstadisticaCasilla
          :seccion="seccion"
          :casilla="casilla"
          :estadisticas="estadisticas"
        />

        <ObservacionesActas />

      </div>
    </div>
  </Layout>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import useDetalleCasilla from '@/composables/UseDetalleCasilla';
import Layout from '@/components/Movil/layouts/Layout';
import ResumenVotacion from '@/components/Movil/componentes/ResumenVotacion';
import ObservacionesActas from '@/components/Movil/componentes/ObservacionesActas';
import EstadisticaCasilla from '@/components/Movil/componentes/EstadisticaCasilla.vue';
// import obtenerColoresDeAvatar from '@/utils/obtenerColoresDeAvatar';

export default defineComponent({
  name: 'VpcDetalleDelDistritoYSeccionMovil',
  components: {
    Layout,
    ResumenVotacion,
    ObservacionesActas,
    EstadisticaCasilla,
  },
 
  computed: {
    cargaTerminada() {
      return this.$store.state.cargaTerminada;
    },
    resumen() {
      if (this.$route.params.eleccion === 'G') {
        return this.$store.state.gResumenNivelCasilla
          .datos_distritos[this.distrito - 1]
          .datos_secciones
          .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
          .datos_casillas
          .filter((casilla) => parseInt(casilla.seccion_id) == parseInt(this.seccion)
                          && casilla.casilla_tipo == this.casilla)[0];
      } if (this.$route.params.eleccion === 'D') {
        return this.$store.state.dResumenNivelCasilla
          .datos_distritos[this.distrito - 1]
          .datos_secciones
          .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
          .datos_casillas
          .filter((casilla) => parseInt(casilla.seccion_id) == parseInt(this.seccion)
                          && casilla.casilla_tipo == this.casilla)[0];
      } if (this.$route.params.eleccion === 'A') {
        return this.$store.state.aResumenNivelCasilla
          .datos_municipios[this.distrito - 1]
          .datos_secciones
          .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
          .datos_casillas
          .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
      }
    },
    votosXCasillaTarjetasM() {
      if(typeof this.$route.params !== 'undefined'){
        if (this.$route.params.eleccion === 'G') {
          return this.$store.state.gVotosXCasillaTarjeta.datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'D') {
          return this.$store.state.dVotosXCasillaTarjeta.datos_distritos[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        } if (this.$route.params.eleccion === 'A') {
          return this.$store.state.aVotosXCasillaNivelDistrito.datos_municipios[this.distrito - 1]
            .datos_secciones
            .filter((seccion) => parseInt(seccion.seccion_id) == parseInt(this.seccion))[0]
            .datos_casillas
            .filter((casilla) => casilla.casilla_tipo == this.casilla)[0];
        }
      }
      return '';
    },
  },
  setup(_, { root }) {
    return {
      ...useDetalleCasilla(root),
    };
  },
});
</script>

<style lang="scss" scoped>

.resumen-votacion{
  background-color: #f5f5f5;
//  width: 100%;
  height: 100px;
  border-radius: 10px;
  p{
    font-weight: bolder;
  }
}
.ganador {
  border: 3px solid #d2127e;
  .grilla-votos {
    p > span {
      color: #d2127e;
    }
  }
}
.totalVotos {
  h1,p {
    color: #582D73;
  }
  
}
.resumen-voto-candidatura {
  background-color: #f5f5f5;
  padding: 10px;

  .grilla-datos-candidatura {
    display: grid;
    grid-template-columns: 75px 1fr 30px;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;

    .foto {
      img {
        max-height: 50px;
        display: block;
        margin: 0 auto;
        padding: 3px;
      }
    }

    .nombre {
      font-size: 16px;
    }
  }

  .grilla-votos, .grilla-distribucion-votos {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: flex-end;
    gap: 30px;
  }

  .grilla-distribucion-votos {
    background-color: #d3d3ec;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: -10px;
    padding: 10px;
  }
}

.btn-link {
  border-radius: 15px;
  background-color: #582D73;
  border-color: transparent;
}

.grilla-selects {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-bottom: 20px;

  select {
    border: 1px solid #fff;
    border-radius: 15px;
    width: 100%;
    padding: 15px;
    appearance: none;
    color: #fff;

    &:hover {
      cursor: pointer;
    }
  }

  .secciones > select {
    background-color: #582D73;
  }
  .casillas > select {
    background-color: #C791A8;
  }
}

.votosEnActasContabilizadas {
  button.btn-ver-acta {
    background-color: #C791A8;
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    border-color: transparent !important;
    box-shadow: none !important;
    font-size: 18px;
  }
  
  .boton{
    .recarga {
      float: right;
      border: none;
      border-radius: 10px;
      padding: 7px 10px;
      width: 250px;
      background-color: #C791A8;
      color: #fff;
      .enproceso{
      background-color: #372c31;

      }
    }
    .recarga2 {
      float: right;
      border: none;
      border-radius: 10px;
      padding: 7px 10px;
      width: 250px;
      background-color: #edcad9;
      color: #fff;
     
    }

  }
  button.btn-ver-acta-proceso {
    background-color: #ddaec2e9;
    width: 100%;
    max-width: 250px;
    border-radius: 10px;
    border-color: transparent !important;
    box-shadow: none !important;
    font-size: 18px;
    
  }
}
</style>
